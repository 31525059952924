import React, { useEffect, useContext, useRef, useState } from 'react';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { getPageSessionId } from '@buzzfeed/adlib/dist/module/services/page-targeting';
import tracking from '../../services/tracking';
import { AnalyticsContext } from '../../context';
import { VideoThumbnailImage } from '../VideoThumbnailImage';
import {
  ACCOUNT_CID as CID,
  CONNATIX_PLAYER_DEFAULTS,
  CONNATIX_PLAYER_AU
} from './constants';
import PropTypes from 'prop-types';

const configEnums = {
  QualityEnum: {
    Medium480p: '',
  },
};

function ConnatixPlayer({
  aspectRatio,
  isPotrait,
  isSquare,
  isVertical,
  isWide,
  name,
  pageType,
  poster,
  posterFetchPriority,
  renderId,
  showBigButton,
  trackingFields,
  videoUrl,
}) {
  let connatixPlayerId = '';
  let videoViewEventCount = 0;
  const scriptId = renderId; // an id made from videoId
  const timeoutId = useRef(null);
  const timeStarted = useRef(null);
  const playedFor = useRef(null);
  const pageInfo = useContext(AnalyticsContext);
  const adsContext = useContext(AdsContext);
  const [pageSessionId, setPageSessionId] = useState(null);
  const [ratioWidth, ratioHeight] = aspectRatio.split(':');
  let gdpr = adsContext?.gdpr;

  let allConnatixIds = CONNATIX_PLAYER_DEFAULTS;

  const { userCountry } = adsContext.env || 'us';
  if(userCountry === 'au' || userCountry === 'nz') {
    allConnatixIds = CONNATIX_PLAYER_AU;
  }

  if (pageType === 'home') {
    connatixPlayerId = isVertical
      ? allConnatixIds.homepageVertical
      : allConnatixIds.homepageBasic;
  } else if (pageType === 'recipe') {
    connatixPlayerId = allConnatixIds.generalRecipe;
  } else {
    connatixPlayerId = isVertical
      ? allConnatixIds.generalVertical
      : allConnatixIds.generalBasic;
  }

  /* istanbul ignore next */
  /* eslint-disable camelcase */
  function videoViewEventTracker() {
    const {
      position_in_unit,
      subunit_name,
      subunit_type,
      unit_name,
      unit_type,
    } = trackingFields;
    tracking.trackVideoView({
      eventInfo: {
        item_name: name,
        // check for whether key exists, because 0 is a valid false-y value
        position_in_subunit:
          'position_in_subunit' in trackingFields
            ? trackingFields.position_in_subunit
            : null,
        position_in_unit: position_in_unit || 0,
        subunit_name: subunit_name || '',
        subunit_type: subunit_type || '',
        unit_name,
        unit_type,
      },
      pageInfo,
    });
  }

  function videoCollapseTracker() {
    tracking.trackContentAction({
      eventInfo: {
        item_name: 'hide',
        item_type: 'button',
        action_type: 'hide',
        action_value: 'ad',
        unit_name: 'top_ad',
        unit_type: 'modal',
      },
      pageInfo,
    });
  }
  /* eslint-enable camelcase */

  useEffect(() => {
    if (!gdpr) {
      return;
    }

    getPageSessionId({gdpr}).then(psi => {
      setPageSessionId(psi);
    });
  }, [gdpr]);

  useEffect(() => {
    if (pageSessionId === null) {
      return;
    }
    // connatix tracking pixel for tasty
    const ctp = new Image().src = `https://capi.connatix.com/tr/si?token=${connatixPlayerId}&cid=${CID}`; // eslint-disable-line max-len, no-unused-vars

    window.cnx.cmd.push(() => {
      const playerArgs = {
        customParam1: pageSessionId,
        playerId: connatixPlayerId,
        settings: {
          customization: {
            ratioWidth,
            ratioHeight,
          },
          playlist: [
            {
              sources: [
                {
                  file: videoUrl,
                  quality: configEnums.QualityEnum.Medium480p,
                },
              ],
            },
          ],
        },
      };
      const player = window.cnx(playerArgs);
      /* istanbul ignore next */
      player.render(scriptId, (renderError, playerApi) => {
        if (renderError) {
          console.warn('An error occured while rendering the player');
        } else {
          let firstFloat = true;
          playerApi.on('playerFloatChanged', (event) => {
            if (firstFloat && event === 'float') {
              firstFloat = false;
              const btnCollapse = document.getElementsByClassName('cnx-dismiss-btn')[0];
              const handleClick = () => {
                videoCollapseTracker();
                btnCollapse.removeEventListener('click', handleClick);
              }
              btnCollapse.addEventListener('click', handleClick);
            }
          });
          playerApi.on('play', () => {
            timeStarted.current = new Date().getTime() / 1000;
            timeoutId.current = setTimeout(() => {
              playedFor.current =
                new Date().getTime() / 1000 - timeStarted.current;
              if (videoViewEventCount < 5 && playedFor.current >= 3) {
                videoViewEventTracker();
                videoViewEventCount++;
              }
            }, 3000);
          });
        }
      });

      /* istanbul ignore next */
      return () => {
        if (player) {
          player.destroy();
        }
      };
    });
  }, [pageSessionId]);

  const returnCnxValue = scriptId ? (
    <div
      className={`${showBigButton ? 'bf-cnx-override-big-button' : ''} ${
        isSquare ? 'video-1-1' : ''
      } ${isWide ? 'video-16-9' : ''} ${isPotrait ? 'video-4-5' : ''}`}
    >
      <script id={scriptId} />
    </div>
  ) : (
    <VideoThumbnailImage
      aspectRatio={aspectRatio}
      poster={poster}
      posterFetchPriority={posterFetchPriority}
      name={name}
    />
  );

  return returnCnxValue;
}

ConnatixPlayer.defaultProps = {
  aspectRatio: '1:1',
  autoplay: false,
  debug: false,
  hideControlsOnPause: false,
  isRecipe: false,
  muted: true,
  showBigButton: false,
  videoAdUrl: null,
};

ConnatixPlayer.propTypes = {
  aspectRatio: PropTypes.string.isRequired,
  autoplay: PropTypes.bool.isRequired,
  isRecipe: PropTypes.bool,
  muted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  showBigButton: PropTypes.bool.isRequired,
  trackingFields: PropTypes.object.isRequired,
};

export default ConnatixPlayer;
